import React, { useState, useEffect, useRef } from "react"
import { Button, Offcanvas, Form, Modal } from "react-bootstrap"
import InputGroup from "react-bootstrap/InputGroup"
import InfoScrollbar from "../UserInfoScroll"
import {
  createStaffToPay,
  sendPaySuccessEmail,
  getWindowsLocation,
  getDeviceCode,
} from "../../api/app.ts"
import { EventUtil } from "../../utils/tools.ts"

const TryFree = props => {
  const [show, setShow] = useState(false)

  const { setShowScroll, priceIds, cbUrl } = props

  const handleClose = () => {
    setShowScroll(true)
    setShow(false)
  }
  const handleShow = () => {
    setShowScroll(false)
    setShow(true)
  }

  const [validated, setValidated] = useState(false)

  const [emailSub, setEmailSub] = useState(true)

  const [email, setEmail] = useState("")
  const [isEmailValid, setIsEmailValid] = useState(true)

  const [priceId, setPriceId] = useState(priceIds.annually)

  const [showPop, setShowPop] = useState(false)
  const handlePopClose = () => setShowPop(false)

  const [popText, setPopText] = useState("")
  const [errPop, setErrPop] = useState(false)

  const [isInputDisplay, setIsInputDisplay] = useState(props.isShowInput.show)
  const timerFlag = useRef(null)

  const handleSubmitEmail = async () => {
    const reg = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/
    const isValidEmail = reg.test(email)
    setIsEmailValid(isValidEmail)
    if (isValidEmail) {
      const params = {
        path: getWindowsLocation(),
        emailSub,
        email,
        priceId,
        origin: getDeviceCode(),
        autoEmail:true
      }
      const { success, data } = await createStaffToPay(params)
      if (success) {
        // sendPaySuccessEmail({ userId: data.userId })
        setShowPop(true)
      }
    }
  }

  const handleResend = () => {
    setShowPop(false)
    handleSubmitEmail()
  }

  useEffect(() => {
    setIsInputDisplay(props.isShowInput.show)
  }, [props.isShowInput])

  return (
    <>
      <section
        className="tryFreeFive"
        id="tryFreeFive"
        style={{
          bottom: isInputDisplay ? 0 : "-200px",
          opacity: isInputDisplay ? 1 : "0.5",
        }}
      >
        <div className="fiveTryFreeForm">
          <InputGroup className="fiveTryFreeForm-input">
            <Form.Control
              required
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              id="fiveEmailInput"
              isInvalid={!isEmailValid}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid email address
            </Form.Control.Feedback>
          </InputGroup>
          <Button onClick={handleSubmitEmail} id="fiveTrySubmit">
            Send Me Free Trial
          </Button>
        </div>

        <Form.Check
          className="tryFreeTips"
          type="checkbox"
          label="Get Daily Online Business Tips for Free."
          defaultChecked
          onChange={e => setEmailSub(e.target.checked)}
        />
      </section>

      <Modal
        show={showPop}
        onHide={handlePopClose}
        centered
        className="fivePopContent"
      >
        <Modal.Header closeButton>
          <img src="/assets/icons/logo.png" className="poplogo" />
        </Modal.Header>
        <Modal.Body>
          <p className="top"> <img
            src="/assets/icons/checkbox.png"
            alt="icon"
            width={16}
            height={16}
            style={{ marginRight: "4px" }}
          />
          Free trial invitation has been sent</p>
          <p className="bottom">
            Check your email and activate your Cubo online office now.
          </p>
        </Modal.Body>
        <Modal.Footer className="popFooter">
          <div className="close" onClick={handlePopClose}>
            Got it
          </div>
          <Button variant="primary" className="resend" onClick={handleResend}>
            Resend
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default TryFree
